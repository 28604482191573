<template>
  <div>
    <div style="height:100%" v-if="loadingPage">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else>
      <div class="w-full h-full mb-5">
        <div v-if="!cannotEdit" class="w-full flex justify-end">
          <div
            class="float-right cursor-pointer text-flame text-base font-semibold"
          >
            <span v-if="annualGrossPay.isEdit" @click="editAnnualGross">
              <icon icon-name="icon-edit" size="xs" class="inline-block" />
              <span class="pl-2">Edit</span>
            </span>
            <div v-else class="flex flex-col">
              <div class="flex">
                <span
                  v-if="hasAnnualGross && reviseSalary"
                  @click="
                    reviseSalary.approvalStatus === 'pending'
                      ? $refs.approval_status.toggle(reviseSalary.id)
                      : handleReviseSalary()
                  "
                  class="text-darkPurple"
                >
                  <icon
                    :icon-name="
                      reviseSalary.approvalStatus === 'pending'
                        ? 'icon-eye'
                        : 'undo'
                    "
                    size="xms"
                    class="inline-block"
                  />
                  <span class="pl-2">{{
                    reviseSalary.approvalStatus === "pending"
                      ? "View Approval Status"
                      : "Revise Salary"
                  }}</span>
                </span>

                <span
                  v-if="!reviseSalary"
                  @click="handleReviseSalary"
                  class="text-darkPurple"
                >
                  <icon icon-name="undo" size="xms" class="inline-block" />
                  <span class="pl-2">Revise Salary</span>
                </span>

                <span class="ml-7" @click="saveAnnualGross">
                  <icon icon-name="save" size="xs" class="inline-block" />
                  <span class="pl-2">Save</span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <!--- Alert -->
        <div
          class="flex p-3 w-full mt-6"
          v-if="
            !annualGrossPay.isEdit &&
            reviseSalary &&
            reviseSalary.approvalStatus === 'pending'
          "
          style="background-color: rgba(247, 181, 56, 0.12)"
        >
          <icon
            icon-name="icon-info"
            style="width: 1rem"
            class="text-desire mr-5"
          />

          <span class="flex flex-grow">
            Annual gross pay has been revised and currently undergoing approval.
            View Approval status for more details.
          </span>
        </div>
      </div>

      <div class="w-full pt-4 flex justify-between">
        <div>
          <div class="text-base flex text-jet">
            <p>Payroll Status:</p>
            <p
              class="ml-4"
              :class="
                payrollStatus === 'active' ? ' text-mediumSeaGreen' : 'text-flame'
              "
              v-if="annualGrossPay.isDisabled"
            >
              {{ payrollStatus ? toTitleCase(payrollStatus) : "Inactive" }}
            </p>
          </div>
          <radio-button
            :options="statusOptions"
            v-if="!annualGrossPay.isDisabled"
            col-span="col-span-6"
            class="mt-4 text-darkPurple"
            v-model="payrollStatus"
          />
        </div>
        <div v-if="!annualGrossPay.isDisabled" class="flex">
          <div>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn mt-5 ml-4"
              @click="$router.push({ name: 'PaySettings' })"
            >
              Go to Pay Settings
            </Button>
          </div>
          <div>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn mt-5 ml-4"
              @click="openSettingsModal = true"
            >
              Settings Preferences
            </Button>
          </div>
        </div>
      </div>
      <div class="flex my-7">
        <checkbox
          v-model="compensation.enabledPAYE"
          label="PAYE"
          checkbox-size="width:16px; height:16px; margin: 4px 8px 4px 0;"
          class=""
          :disabled="annualGrossPay.isEdit"
        />
        <checkbox
          v-model="compensation.enabledPension"
          checkbox-size="width:16px; height:16px; margin: 4px 8px 4px 0;"
          label="Pension"
          class="ml-7"
          :disabled="annualGrossPay.isEdit"
        />
        <checkbox
          v-model="compensation.enabledNHF"
          checkbox-size="width:16px; height:16px; margin: 4px 8px 4px 0;"
          class="ml-7"
          label="NHF"
          :disabled="annualGrossPay.isEdit"
        />
      </div>
      <div
        class="grid-cols-12 grid w-full gap-y-7 gap-x-3 my-3 text-darkPurple"
        style="margin-top: 1.125rem"
      >
        <div class="col-span-3">
          <div class="text-sm text-jet">Pay Type</div>
          <div
            class="wrapper h-10 mt-2 capitalize"
            v-if="annualGrossPay.isEdit"
            :class="
              annualGrossPay.isEdit ? 'disabled disabled_hideOutline' : 'disabled'
            "
          >
            {{ payType }}
          </div>
          <c-select
            placeholder="--Select--"
            variant="w-full h-10"
            :options="PayTypeOptions"
            v-model="payType"
            @input="onChangePayType($event)"
            v-else
          />
        </div>
        <div class="col-span-3">
          <div class="text-sm text-jet">Pay Frequency</div>
          <div
            class="wrapper h-10 mt-2 capitalize"
            v-if="annualGrossPay.isEdit"
            :class="annualGrossPay.isEdit ? 'disabled disabled_hideOutline' : 'disabled'"
          >
            {{ payFrequency }}
          </div>
          <c-select
            placeholder="--Select--"
            variant="w-full h-10"
            :options="payFrequencyOptions"
            @onChange="handleFreq"
            v-model="payScheduleId"
            v-else
          />
          <small v-if="!annualGrossPay.isEdit && !PayTypeOptions.length" class="text-flame font-extrabold">
            You need to set up your pay frequencies in pay settings.
          </small>
        </div>
        <div v-if="payType === 'salaried'" class="col-span-3">
          <div class="text-sm text-jet">Pay Grade</div>
          <div
            class="wrapper h-10 mt-2"
            :class="
              annualGrossPay.isEdit ? 'disabled disabled_hideOutline' : 'disabled'
            "
          >
            {{ compensationData.payGradeId || "Autoloaded" }}
          </div>
        </div>
        <div
          class="col-span-3"
          v-if="
            handleShowPayRange
          "
        >
          <div class="flex flex-row justify-start items-center gap-0">
            <span class="text-sm mb-2 text-jet">Pay Range</span>
            <span class="flex items-start mb-2">
              <Icon
                v-tooltip.top="
                  'Pay Range is Autoloaded. You can create a new Paygrade in compensation settings'
                "
                class-name="text-darkPurple h-3 w-1 flex cursor-pointer"
                icon-name="tooltip"
                size="xsm"
              />
            </span>
          </div>
          <div
            style="width: auto"
            class="text-sm"
            :class="
              annualGrossPay.isDisabled
                ? 'payInfo payInfo_disabled'
                : 'payInfo payInfo_fakeInput text-center'
            "
          >
            {{
              `${convertToCurrency(
                compensationData ? compensationData.rangeMinimum : null,
                0
              )} - ${convertToCurrency(
                compensationData ? compensationData.rangeMaximum : null,
                0
              )}`
            }}
          </div>
        </div>
        <div class="col-span-3">
          <label class="text-sm flex text-jet">
            <span>Annual Gross Pay</span>
            <span>
              <Icon
                v-tooltip.top="
                  `After the initial entry, this can be updated with revise salary action beside the save button`
                "
                class-name="text-darkPurple h-3 w-1 mt-1 flex cursor-pointer"
                icon-name="tooltip"
                size="xsm"
              />
            </span>
          </label>
          <div
            class="wrapper h-10 mt-2"
            v-if="annualGrossPay.isEdit || hasAnnualGross"
            :class="
              annualGrossPay.isEdit ? 'disabled disabled_hideOutline' : 'disabled'
            "
          >
            {{ annualGross ? annualGross.toLocaleString('en-US') : annualGross}}
          </div>
          <c-text
            placeholder="--Enter--"
            variant="w-full h-10"
            type="text"
            ref="annaulGross"
            v-model="formattedAnnaulGross"  
            @input="formatAnnualGross($event)"
            v-else
          />
          <small v-if="error" class="text-flame">
            Annual gross salary is more or less than pay range
          </small>
        </div>
        <div v-if="payType === 'salaried'" class="col-span-3">
          <div class="text-sm text-jet">Compa-Ratio</div>
          <div
            class="wrapper h-10 mt-2"
            :class="
              annualGrossPay.isEdit ? 'disabled disabled_hideOutline' : 'disabled'
            "
          >
            {{ compensationData.compaRatio || "Autoloaded" }}
          </div>
        </div>
        <div v-if="payType === 'salaried'" class="col-span-3">
          <div class="text-sm text-jet">Range Penetration</div>
          <div
            class="wrapper h-10 mt-2"
            :class="
              annualGrossPay.isEdit ? 'disabled disabled_hideOutline' : 'disabled'
            "
          >
            <span v-if="!annualGross">{{ 'Autoloaded' }}</span>
            <span v-else-if="compensationData.rangePenetration === null">{{ 'Autoloaded' }}</span>
            <span v-else>{{ compensationData.rangePenetration }}</span>
          </div>
        </div>
        <div v-if="payType === 'Hourly'" class="col-span-3">
          <div class="text-sm text-jet">Rate Per Hour</div>
          <div
            class="wrapper h-10 mt-2"
            v-if="annualGrossPay.isEdit"
            :class="
              annualGrossPay.isEdit ? 'disabled disabled_hideOutline' : 'disabled'
            "
          >
            {{ ratePerHour }}
          </div>
          <c-text
            placeholder="--Enter--"
            variant="w-full h-10"
            v-model="ratePerHour"
            v-else
          />
        </div>
        <div v-if="payType === 'Hourly'" class="col-span-3">
          <div class="text-sm text-jet">Max. No. of Hours Per Day</div>
          <div
            class="wrapper h-10 mt-2"
            v-if="annualGrossPay.isEdit"
            :class="
              annualGrossPay.isEdit ? 'disabled disabled_hideOutline' : 'disabled'
            "
          >
            {{ hoursPerDay }}
          </div>
          <c-text
            placeholder="--Enter--"
            variant="w-full h-10"
            v-model="hoursPerDay"
            v-else
          />
        </div>
        <div v-if="payType === 'Hourly'" class="col-span-3">
          <div class="text-sm text-jet">No. of Weeks Per Year</div>
          <div
            class="wrapper h-10 mt-2"
            v-if="annualGrossPay.isEdit"
            :class="
              annualGrossPay.isEdit ? 'disabled disabled_hideOutline' : 'disabled'
            "
          >
            {{ weeksPerYear }}
          </div>
          <c-text
            placeholder="--Enter--"
            variant="w-full h-10"
            v-model="weeksPerYear"
            v-else
          />
        </div>
        <div v-if="payType === 'Hourly'" class="col-span-3">
          <div class="text-sm text-jet">Estimated Annual Gross</div>
          <div
            class="wrapper h-10 mt-2"
            :class="
              annualGrossPay.isEdit ? 'disabled disabled_hideOutline' : 'disabled'
            "
          >
            {{ estimatedAnnual || "Autoloaded" }}
          </div>
        </div>
        <div class="col-span-3">
          <div class="text-sm text-jet">Effective Tax Rate</div>
          <div
            class="wrapper h-10 mt-2"
            :class="
              annualGrossPay.isEdit ? 'disabled disabled_hideOutline' : 'disabled'
            "
          >
           {{ compensationData ? compensationData.effectiveTaxRate : '---' }}
          </div>
        </div>    
      </div>
    <accordion-pay-info-table
      :gross-earnings-data="grossEarningsData"
      :total-amount="totalGrossAmount"
      :total-percent="totalPercent"
      :variable-table="variableData"
    />
    <revise-employee
      :open-modal="openReviseModal"
      ref="revise_salary"
      :user-details="employeeDetails"
      @close="closeReviseSalary($event)"
    />
    <RightSideBar
      v-if="openSettingsModal"
      @submit="saveSettings"
      submit="Save"
      @close="openSettingsModal = false"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <p class="text-darkPurple">Settings Preferences</p>
      </template>
      <div class="mb-8 -mt-8">
        <alert
          message="Let us know how you would prefer to treat this employee’s pay settings."
          variant="primary"
          :time="2"
          style="
            position: relative;
            right: 0;
            top: 35px;
            border: 1px solid #e99323;
            box-shadow: none;
            background: rgba(244, 213, 176, 0.15);
            color: black;
          "
        />
      </div>
      <div class="mt-16">
        <label class="text-romanSilver font-bold text-xs"> PAYE </label>
        <checkbox
          v-model="inheritGeneralPayeSetting"
          label="Allow changes in general settings to override employee PAYE settings"
          label-style=" margin-left: 8px"
          class="mt-2"
          checkbox-size="width:16px; height:16px; margin: 2px 4px 0 0;"
        />
      </div>
      <div class="mt-4">
        <label class="text-romanSilver font-bold text-xs"> Pension </label>
        <checkbox
          v-model="inheritGeneralPensionSetting"
          label="Allow changes in general settings to override employee pension settings"
          class="mt-2"
          label-style=" margin-left: 8px"
          checkbox-size="width:16px; height:16px; margin: 2px 4px 0 0;"
        />
      </div>
      <div class="mt-4">
        <label class="text-romanSilver font-bold text-xs">NHF </label>
        <checkbox
          v-model="inheritGeneralNHFSetting"
          label="Allow changes in general settings to override employee NHF settings"
          class="mt-2"
          label-style=" margin-left: 8px"
          checkbox-size="width:16px; height:16px; margin: 2px 4px 0 0;"
        />
      </div>
    </RightSideBar>
    <template>
      <Modal v-if="isPaybandWarning" class="">
        <Card class="p-2 px-3 flex flex-col gap-3 max-w-md">
          <template>
            <div class="border-b border-romanSilver">
              <p class="text-jet font-bold text-base">Action Required!</p>
            </div>
            <div class="w-full flex flex-col justify-start gap-2">
              <div class="relative mb-5 w-full">
                <div
                  class="
                    w-full
                    px-5
                    py-2
                    flex
                    self-center
                    mb-2
                    rounded-md
                    bg-yellow-100
                    border-carrotOrange border
                  "
                >
                  <div class="flex justify-start items-center gap-2">
                    <Icon
                      icon-name="icon-info"
                      size="xs"
                      class="text-carrotOrange"
                    />
                    <span
                      class="
                        text-carrotOrange
                        w-full
                        rounded-md
                        text-center text-sm
                      "
                    >
                      <span v-if="annualGross < compensationData.rangeMinimum">
                        New annual gross salary is less than pay range
                      </span>
                      <span v-if="annualGross > compensationData.rangeMaximum">
                        New annual gross salary is more than pay range
                      </span>
                    </span>
                  </div>
                </div>
                <div
                  class="w-full flex flex-col justify-start items-start gap-1"
                >
                  <div class="flex justify-start items-center gap-2">
                    <p class="font-semibold text-sm text-jet">Salary Range:</p>
                    <p class="font-bold text-sm leading-5 text-jet">
                      <span v-if="compensationData">
                        {{ convertToCurrency(compensationData.rangeMinimum) }}
                        -
                        {{ convertToCurrency(compensationData.rangeMaximum) }}
                      </span>
                      <span v-else>-</span>
                    </p>
                  </div>
                  <div class="flex justify-start items-center gap-2">
                    <p class="font-semibold text-sm text-jet">
                      Annual Gross Pay:
                    </p>
                    <p class="font-bold text-sm text-jet">
                      {{ convertToCurrency(annualGross) }}
                    </p>
                  </div>
                </div>
              </div>
              <p>Do you wish to continue?</p>
              <div class="flex justify-end items-center gap-2">
                <CButton
                  label="Cancel"
                  class="button-class button-class-secondary"
                  @onClick="isPaybandWarning = false"
                  :disabled="isLoading"
                />
                <CButton
                  class="button-class button-class-pimary btn-bg"
                  label="Continue"
                  @onClick="saveAnnualGross"
                  :disabled="isLoading"
                />
              </div>
            </div>
          </template>
        </Card>
      </Modal>
    </template>

    <ApprovalProgress ref="approval_status" />
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Checkbox from "@scelloo/cloudenly-ui/src/components/checkbox";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import CButton from "@scelloo/cloudenly-ui/src/components/button";
import { mapState } from "vuex";
import RadioButton from "@/components/RadioButton";
import Button from "@/components/Button";
import Alert from "@scelloo/cloudenly-ui/src/components/alert";

export default {
  components: {
    RadioButton,
    CText,
    CSelect,
    Checkbox,
    Button,
    Alert,
    Card,
    CButton,
    AccordionPayInfoTable: () => import("./accordionPayInfoTable"),
    ReviseEmployee: () => import("../../../sideActions/reviseEmployee"),
    RightSideBar: () => import("@/components/RightSideBar"),
    ApprovalProgress: () => import("@/components/ApprovalStatus"),
    Modal: () => import("@/components/Modal"),
  },
  props: {
    cannotEdit: {
      type: Boolean,
      default: false,
    },
    totalGrossEarning: {
      type: Object,
      default: () => ({}),
    },
    reviseSalary: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      annualGrossPay: {
        isDisabled: true,
        isEdit: true,
      },
      isLoading: false,
      openReviseModal: false,
      loadingPage: true,
      isPaybandWarning: false,
      inheritGeneralPensionSetting: false,
      inheritGeneralPayeSetting: false,
      inheritGeneralNHFSetting: false,
      openSettingsModal: false,
      disableGross: false,
      payBandError: false,
      payrollStatus: null,
      grossEarningsData: [],
      totalGrossAmount: 0,
      totalPercent: 0,
      variableData: {},
      hasAnnualGross: false,
      hasPayee: null,
      hasPension: null,
      hasNhf: null,
      payFrequency: null,
      payScheduleId: null,
      payType: "salaried",
      oldFrequency: [],
      payGrade: null,
      taxRate: null,
      ratePerHour: null,
      hoursPerDay: null,
      weeksPerYear: null,
      estimatedAnnual: null,
      compensationData: {
        compaRatio: null,
        grossPay: null,
        payGradeId: "",
        rangeMaximum: 0,
        rangeMidPoint: 0,
        rangeMinimum: 0,
        rangePenetration: 0,
      },
      salaried: [],
      payFrequencies: [
        {
          slug: "monthly",
          name: "Monthly",
        },
        {
          slug: "twice_monthly",
          name: "Twice Monthly",
        },
        {
          slug: "weekly",
          name: "Four Times Monthly",
        },
      ],
      compensation: {
        enabledPAYE: false,
        enabledPension: false,
        enabledNHF: false,
      },
      error: false,
      setSalary: "",
      annualGross: "",
      statusOptions: [
        { name: "Active", radioName: "payRollStatus", value: "active" },
        { name: "Inactive", radioName: "payRollStatus", value: "inactive" },
      ],
      payFrequencyOptions: [],
      PayTypeOptions: [],
      hourlyOptions: [],
      salariedOptions: [],
      newSalary: ''
    };
  },
  computed: {
    ...mapState({
      employeeDetails: (state) => state.employee.allEmployeeDetails,
    }),
    totalRate() {
      const result = 7;
      return result;
    },
    totalAmount() {
      const result = "---";
      return result;
    },
    handleShowPayRange() {
      if (
        this.$store.state.subscriptionStatus === "trial" &&
        this.payType === "salaried"
      )
        return true;
      if (
        this.$store.state.subscription &&
        this.$store.state.subscription.plan !== "basic" &&
        this.payType === "salaried"
      )
        return true;
      return false
    },
    formattedAnnaulGross: {
      get() {
        if (this.annualGross) {
          return Number(this.annualGross).toLocaleString('en-US');
        }
        return '';
      },
      set() {}
    }
  },
  watch: {
    annualGross(value) {
      let newValue = 0;

      if (value) {
        if (typeof value === "number") {
          newValue = value;
        } else {
          newValue = Number(value.split(",").join(""));
        }
        if (
          this.$store.state.subscription &&
          this.$store.state.subscription.plan !== "basic" &&
          this.employeeDetails.compensation.rangeMaximum !== 0
        ) {
          const salary = this.employeeDetails.compensation;
          const lowerBand = Number(salary.rangeMinimum);
          const upperBand = Number(salary.rangeMaximum);

          if (newValue >= lowerBand && newValue <= upperBand) {
            this.error = false;
          } else {
            this.error = true;
          }
        }
        newValue = newValue.toString();
      }

      return Number(newValue);
    },
  },
  methods: {
    formatAnnualGross(value) {
      const annualGrossRef = this.$refs.annaulGross;
      value = value.replace(/[^0-9.]/g, "");

      // Prevented multiple dots
      if (value.split('.').length > 2) {
        value = value.slice(0, value.lastIndexOf('.'));
      }
      
      // Formated the number with commas
      const parts = value.split('.');
      if (parts[0]) {
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      annualGrossRef.inputValue = parts.join('.');
      
      this.annualGross = value;
    },
    async handleReviseSalary() {
      try {
        await this.$handlePrivilege(
          "employeeDirectory",
          "reviseEmployeeSalary"
        );
        this.$refs.revise_salary.toggle(this.employeeDetails);
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    closeReviseSalary(data) {
      this.openReviseModal = false
      this.annualGross = data
    },
    handleModalClose() {
      this.$store.dispatch("employee/getOneEmployeeAll", this.$route.params.id)
        .then(() => { this.getDetails(); });
      this.getEmployeeSalary();
      this.openReviseModal = false;
    },
    async getEmployeeSalary() {
      const { data } = await this.$_getEmployeeSalaryBreakDown({
        id: this.$route.params.id,
      });
      this.grossEarningsData = data.salaryBreakdown;
      this.totalGrossAmount = data.totalGrossAmount;
      this.totalPercent = data.totalGrossPercent;
      this.variableData = data.variableSalaryBreakdown;
      this.$emit("grossPay");
    },
    async editAnnualGross() {
      try {
        await this.$handlePrivilege("employeeDirectory", "editEmployeePayInfo");
        if (
          this.$store.state.subscription &&
          this.$store.state.subscription.plan !== "basic"
        ) {
          // if (this.employeeDetails.employeePayBand) {
          //   this.disableGross = false;
          //   this.payBandError = false;
          // } else {
          //   this.disableGross = true;
          //   this.payBandError = true;
          // }
        } else {
          this.disableGross = false;
          this.payBandError = false;
        }
        this.annualGrossPay.isDisabled = false;
        this.annualGrossPay.isEdit = false;
        this.annualGross =
          typeof this.annualGross === "string"
            ? Number(this.annualGross.split(",").join(""))
            : this.annualGross;
      } catch (err) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async getDetails() {
      this.loadingPage = true
      const {
        grossPay,
        payrollStatus,
        payType,
        payFrequency,
        enabledPAYE,
        enabledPension,
        enabledNHF,
        compensation,
        inheritGeneralPensionSetting,
        inheritGeneralPayeSetting,
        inheritGeneralNHFSetting,
      } = this.employeeDetails;
      this.hasAnnualGross = !!grossPay;
      this.annualGross = grossPay
        ? parseFloat(grossPay.toFixed(2)).toLocaleString()
        : null;
      this.payrollStatus = payrollStatus;
      this.payType = payType || "salaried";
      this.inheritGeneralPensionSetting = inheritGeneralPensionSetting;
      this.compensationData = compensation;
      this.inheritGeneralPayeSetting = inheritGeneralPayeSetting;
      this.inheritGeneralNHFSetting = inheritGeneralNHFSetting;
      this.payFrequency = this.getSlug(payFrequency);
      this.compensation = {
        enabledPAYE,
        enabledPension,
        enabledNHF,
      };
      this.loadingPage = false
    },
    saveAnnualGross() {
      const { rangeMinimum, rangeMaximum } = this.compensationData;
      const isInRange = _.inRange(this.annualGross, rangeMinimum, rangeMaximum);
      const isPayband = _.size(_.keys(this.compensationData));
      if (!isInRange && !!isPayband && !this.isPaybandWarning) {
        this.isPaybandWarning = true;
        this.isLoading = false;
        return undefined;
      }
      this.isLoading = true;

      return this.$_editEmployeeByType({
        id: this.$route.params.id,
        type: "grosspay-info",
        payload: {
          grossPay:
            typeof this.annualGross === "string"
              ? Number(this.annualGross.split(",").join(""))
              : Number(this.annualGross),
          payrollStatus: this.payrollStatus,
          enabledPAYE: this.compensation.enabledPAYE,
          enabledPension: this.compensation.enabledPension,
          enabledNHF: this.compensation.enabledNHF,
          payType: this.payType,
          payFrequency: this.getFreqName(this.payFrequency),
          payScheduleId: this.payScheduleId,
          containsEmploymentInfo: true,
        },
      }).then(() => {
        this.$store.dispatch("employee/getOneEmployeeAll", this.$route.params.id).then(() => {
          if (this.annualGross) {
            this.getEmployeeSalary();
            this.hasAnnualGross = true;
          } else {
            this.hasAnnualGross = false;
          }
          this.isLoading = false;
          this.isPaybandWarning = false;
          this.$emit("grossPay");
          this.getDetails();
          this.annualGrossPay.isDisabled = true;
          this.disableGross = true;
          this.annualGrossPay.isEdit = true;
          this.$toasted.success("Salary breakdown saved successfully", {
            duration: 5000,
          });
        });
      }).catch((error) => {
        this.$toasted.error(error, {duration: 5000 });
      });
    },
    getSlug(name) {
      if (name === "weekly") return "Four Times Monthly";
      if (name === "monthly") return "Monthly";
      if (name === "twice_monthly") return "Twice Monthly";
      return "";
    },
    getFreqName(slug) {
      if (slug === "Four Times Monthly") return "weekly";
      if (slug === "Monthly") return "monthly";
      if (slug === "Twice Monthly") return "twice_monthly";
      return "";
    },
    handleFreq(data) {
      const findSalaried = this.payFrequencyOptions.find((item) => item.id === data);
      if (findSalaried) {
        this.payFrequency = findSalaried.name;
      }
    },
    getFrequencyId(name) {
      const formattedName = name;
      const findId = this.payFrequencyOptions.find((item) => item.name === formattedName);
      if (findId) this.payScheduleId = findId.id;
    },
    getPayFrequency(id) {
      const findSalaried = this.payFrequencyOptions.find((item) => item.id === id);
      if (findSalaried) {
        this.getSlug(findSalaried.name);
      }
    },
    onChangePayType(payType) {
      if (payType === 'salaried') this.payFrequencyOptions = this.salariedOptions
      else if (payType === 'hourly') this.payFrequencyOptions = this.hourlyOptions
    },
    getPayFrequencySetup() {
      this.$_getPaySchedulesSetup().then(({ data }) => {
          if (data.hourlyIsSetup) this.PayTypeOptions.push({ name: 'Hourly', id: 'hourly' })
          if (data.salariedIsSetup) this.PayTypeOptions.push({ name: 'Salaried', id: 'salaried' })
          this.hourlyOptions = data.hourly?.map(i => ({ ...i, name: this.getSlug(i.name) }))
          this.salariedOptions = data.salaried?.map(i => ({ ...i, name: this.getSlug(i.name) }))
          this.onChangePayType(this.payType)
          this.getFrequencyId(this.payFrequency)
        }).catch((error) => {
          const message = "An Error occured, please contact your administrator"
          this.$toasted.error(error || message, { duration: 5000 });
        });
    },
    saveSettings() {
      const payload = {
        inheritGeneralPensionSetting: this.inheritGeneralPensionSetting,
        inheritGeneralPayeSetting: this.inheritGeneralPayeSetting,
        inheritGeneralNHFSetting: this.inheritGeneralNHFSetting,
      };
      this.$_saveSettingPreference(this.$route.params.id, payload)
        .then(() => {
          this.openSettingsModal = false;
          this.handleModalClose();
          this.$toasted.success("Settings saved successfully", {
            duration: 5000,
          });
        })
        .catch(() => {
          this.openSettingsModal = false;
          this.$toasted.error(
            "Settings not saved please contact your administrator",
            { duration: 5000 }
          );
        });
    },
  },
  async mounted() {
    await this.$store.dispatch(
      "employee/getOneEmployeeAll",
      this.$route.params.id
    );
   
    await this.getDetails();
    await this.getEmployeeSalary();
    await this.getPayFrequencySetup();
  },
};
</script>

<style lang="scss" scoped>
.payInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  padding: 10px 15px;
  max-height: 40px;
  width: 251px;
  &_fakeInput {
    background: #f7f7ff;
    border-color: #878e99;
  }
  &_disabled {
    background: #eeeeee;
    border: none;
    color: #878e99;
  }
  &_unallocated {
    padding: 12px 16px;
    background: #fff5f5;
    border-color: #ea3c53;
    max-height: 65px;
  }
}
</style>
<style scoped>
.button_class {
  padding: 10px 40px !important;
  font-weight: 600;
  display: flex;
  justify-self: end;
}
.button-class-pimary {
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 10px 40px !important;
}
.button-class-secondary {
  color: #321c3b !important;
  background: white !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 10px 40px !important;
}
.alert-style {
  position: relative;
  right: 0;
  top: -5px;
  box-shadow: none;
  background: #ffffff;
  color: #e99323;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid rgb(254 202 202);
}

.btn-bg {
    background-color: var(--dynamic-Background) !important;
 }
</style>